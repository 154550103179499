import PropTypes from "prop-types";
import React from "react";
import { motion } from "framer-motion";

// Config
import routes from "../config/routes";

//Components
import Checkbox from "../components/Checkbox";
import Button from "../components/Button";
import LinkAnchor from "../components/LinkAnchor";
import TableDataUAX from "../components/TableDataUAX";

export default class PolicyUAX extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
    };
  }
  handleCheckbox = (checkbox, state) => {
    this.setState({ isDisabled: !checkbox });
  };
  render() {
    return (
      <motion.section
        className="o-wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="o-container">
          <div className="o-display--flex o-display--flex-v-center o-display--flex-column-direction">
            <h5 className="o-margin o-margin--bottom-size-l">AVISO LEGAL</h5>
            <div className="o-margin o-margin--bottom-size-xl">
              <h6 className="o-margin o-margin--bottom-size-m">
                1) INFORMACIÓN DEL TITULAR
              </h6>
              <p className="o-margin o-margin--bottom-size-l">
                En cumplimiento del artículo 10 de la Ley 34/2002, de 11 de
                julio, de Servicios de la Sociedad de la Información y Comercio
                Electrónico, a continuación, se exponen los datos
                identificativos del Titular:
              </p>
              <TableDataUAX />
            </div>
            <div className="o-margin o-margin--bottom-size-xl">
              <h6 className="o-margin o-margin--bottom-size-m">
                2) CONDICIONES DE ACCESO Y USO
              </h6>
              <p className="o-margin o-margin--bottom-size-l">
                El objeto de las presentes Condiciones Generales de Acceso y Uso
                (en adelante, Condiciones) es regular el acceso y la utilización
                del Sitio Web.
                <br />
                <br />
                Cualquier persona que acceda a la Web de{" "}
                <strong>FACTHUM</strong> tendrá la consideración de Usuario. El
                Usuario se compromete a utilizar la página Web y aquellos
                servicios puestos a su disposición a través de la misma, de
                manera acorde a la Ley, moral, buenas costumbres y orden
                público, así como con lo dispuesto en el presente clausulado. En
                consecuencia, queda obligado a no utilizar la Web con fines o
                efectos ilícitos y/o contrarios a lo establecido, lesivos de
                derechos y/o intereses de terceros o que, de cualquier forma,
                puedan dañar la Web o impedir su normal uso, o de los servicios
                accesibles a través de la misma.
              </p>
              <p>
                La utilización de la Web y/o de sus Servicios, supondrá la
                aceptación plena y sin reservas, y la validez, de todas y cada
                una de las cláusulas recogidas en la última versión actualizada
                de este Aviso Legal, por lo que el Usuario deberá ser consciente
                de la importancia de leerlas cada vez que visite la Web.
              </p>
              <p>
                El Usuario declara ser mayor de edad y disponer de la capacidad
                jurídica suficiente para vincularse por las presentes
                Condiciones. Por lo tanto, este Sitio Web de FACTHUM no se
                dirige a menores de edad sin representación. FACTHUM declina
                cualquier responsabilidad por el incumplimiento de este
                requisito.
              </p>
            </div>
            <div className="o-margin o-margin--bottom-size-xl">
              <h6 className="o-margin o-margin--bottom-size-m">
                3) PROPIEDAD INTELECTUAL
              </h6>
              <p>
                El código fuente, los diseños gráficos, las imágenes, las
                fotografías, los vídeos, los sonidos, las animaciones, el
                software, los textos, así como la información y los contenidos
                que se recogen en la Web están protegidos por la legislación
                española sobre los derechos de propiedad intelectual e
                industrial a favor de <strong>FACTHUM</strong> de terceros
                licenciadores. No se permite la reproducción y/o publicación,
                total o parcial, de la Web, ni su tratamiento informático, su
                distribución, su difusión, ni su modificación, transformación o
                descompilación, ni demás derechos reconocidos legalmente a su
                titular, sin el permiso previo y por escrito del mismo. El
                Usuario, única y exclusivamente, puede utilizar el material que
                aparezca en la Web para su uso personal y privado, quedando
                prohibido su uso con fines comerciales o para incurrir en
                actividades ilícitas. Todos los derechos derivados de la
                propiedad intelectual están expresamente reservados por{" "}
                <strong>FACTHUM</strong> los terceros licenciadores, que velará
                por el cumplimiento de las anteriores condiciones como por la
                debida utilización de los contenidos presentados en sus páginas
                web, ejercitando todas las acciones civiles y penales que le
                correspondan en el caso de infracción o incumplimiento de estos
                derechos por parte del Usuario.
              </p>
            </div>
            <div className="o-margin o-margin--bottom-size-xl">
              <h6 className="o-margin o-margin--bottom-size-m">
                4) POLÍTICA DE PRIVACIDAD.
              </h6>
              <p>
                La presente política se apoya para su confección en base a la
                normativa española y europea vigente en materia de protección de
                datos personales en internet. En concreto, la misma respeta las
                siguientes normas:
                <br />
                <br />
                - La Ley Orgánica 3/2018, de 5 de diciembre, de Protección de
                Datos Personales y garantía de los derechos digitales
                (LOPD-GDD).
                <br />
                <br />
                - La Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de
                la Información y de Comercio Electrónico (LSSI-CE).
                <br />
                <br />
              </p>
              <p>
                <strong>FACTHUM</strong> se compromete a proteger la privacidad
                de los usuarios que accedan a esta web y/o cualquiera de sus
                servicios. La utilización de la web y/o cualquiera de los
                servicios ofrecidos por <strong>FACTHUM</strong>, implica la
                aceptación por parte del usuario de las disposiciones contenidas
                en la presente Política y que sus datos personales sean tratados
                según se estipula. Por favor, tenga en cuenta que, aunque pueda
                haber enlaces de nuestra web a otras webs, esta Política no se
                aplica a otras compañías u organizaciones a las que la web esté
                redirigida. FACTHUM no controla el contenido de las webs de
                terceros ni acepta ningún tipo de responsabilidad por el
                contenido o las políticas de privacidad de estos sitios web.
                <br />
                <strong>FACTHUM</strong> actúa como RESPONSABLE DE TRATAMIENTO
                de datos personales cuando utiliza su web para dar servicio a
                sus propios empleados y candidatos, aplicando su propia Política
                de Privacidad.
                <br />
                En los casos donde <strong>FACTHUM</strong> hace cesión o da
                licencia de uso de su plataforma web para que sus organizaciones
                y empresas clientes la utilicen con sus empleados y/o
                candidatos, <strong>FACTHUM</strong>
                actúa como ENCARGADO DE TRATAMIENTO y se remite y adhiere a las
                medidas y condiciones expuestas en la política o Aviso de
                Privacidad de su organización o empresa cliente (ver apartado
                correspondiente en la web), quien resulta actuar como
                RESPONSABLE DE TRATAMIENTO.
              </p>
            </div>
            <div className="o-margin o-margin--bottom-size-xl">
              <h6 className="o-margin o-margin--bottom-size-m">
                5) ¿QUÉ MEDIDAS DE SEGURIDAD APLICAMOS?{" "}
              </h6>
              <p className="o-margin o-margin--bottom-size-l">
                Aplicamos las medidas de seguridad establecidas en el artículo
                32 del RGPD, por lo tanto hemos adoptado las medidas de
                seguridad necesarias para garantizar un nivel de seguridad
                adecuado al riesgo del tratamiento de los datos que realizamos,
                con mecanismos que nos permiten garantizar la confidencialidad,
                integridad, disponibilidad y resiliencia permanente de los
                sistemas y servicios de tratamiento.
              </p>
              <p>Algunas de estas medidas son:</p>
              <ul className="o-margin o-margin--bottom-size-l">
                <li>
                  • Información de las políticas de tratamiento de los datos al
                  personal.
                </li>
                <li>• Realización de copias de seguridad periódicas.</li>
                <li>• Control de acceso a los datos.</li>
                <li>
                  • Procesos de verificación, evaluación y valoración regulares.
                </li>
              </ul>
            </div>
            <div className="o-margin o-margin--bottom-size-xl">
              <h6 className="o-margin o-margin--bottom-size-m">
                6) EXCLUSIÓN DE RESPONSABILIDAD
              </h6>

              <p className="o-margin o-margin--bottom-size-l">
                <strong>FACTHUM</strong> actúa con la máxima diligencia posible
                para que los datos y la información que ofrece en su sitio web
                esté actualizada en todo momento. No garantiza ni se hace
                responsable la exactitud y actualización de los contenidos del
                sitio web, reservándose el derecho a modificar estos contenidos
                en cualquier momento. FACTHUM no será responsable de la
                información que se pueda obtener a través de enlaces incluidos
                en el sitio web.
              </p>
              <p className="o-margin o-margin--bottom-size-l">
                Las relaciones comerciales entre clientes se regirán por las
                condiciones generales que, si fuera necesario, se establecerían
                por FACTHUM en un documento específico a este efecto, o para los
                pactos concretos que se puedan acordar con los clientes.
              </p>
            </div>
            <div className="o-margin o-margin--bottom-size-xl">
              <h6 className="o-margin o-margin--bottom-size-l">
                7) POLÍTICA DE INFORMACIÓN Y PUBLICIDAD COMERCIAL.
              </h6>

              <p className="o-margin o-margin--bottom-size-l">
                <strong>FACTHUM</strong> se compromete a través de este medio a
                no realizar publicidad engañosa. A estos efectos, por lo tanto,
                no serán considerados como publicidad engañosa los errores
                formales o numéricos que puedan encontrarse a lo largo del
                contenido de las distintas secciones de la Web, producidos como
                consecuencia de un mantenimiento y/o actualización incompleta de
                la información contenida en estas secciones. FACTHUM, como
                consecuencia de lo dispuesto en este apartado, se compromete a
                corregirlo tan pronto como tenga conocimiento de dichos errores.
              </p>
              <p>
                <strong>FACTHUM</strong> se compromete a{" "}
                <strong>
                  no remitir comunicaciones comerciales sin identificarlas como
                  tales
                </strong>
                , conforme a lo dispuesto en la Ley 34/2002 de Servicios de la
                Sociedad de la Información y de comercio electrónico. A estos
                efectos no será considerado como comunicación comercial toda la
                información que se envíe a los clientes de{" "}
                <strong>FACTHUM</strong> que tenga por finalidad el
                mantenimiento de la relación contractual existente entre cliente
                y <strong>FACTHUM</strong>, así como el desempeño de las tareas
                de información y otras actividades propias del servicio que el
                cliente tiene contratado.
              </p>
            </div>
            <div className="o-margin o-margin--bottom-size-xl">
              <h6 className="o-margin o-margin--bottom-size-m">
                8) CONDICIONES DE USO. JURISDICCIÓN Y LEYES APLICABLES.
              </h6>

              <p className="o-margin o-margin--bottom-size-l">
                El uso de este sitio web implica la aceptación plena de los
                términos del presente aviso legal. El presente Aviso Legal y
                todas las relaciones que se establezcan entre{" "}
                <strong>FACTHUM</strong>, el Usuario de la Web y sus servicios
                se regirán por lo establecido en la legislación española.
              </p>
            </div>
            <p>4 de febrero de 2025.</p>
          </div>
        </div>
      </motion.section>
    );
  }

  static propTypes = {
    // name: PropTypes.string.isRequired, // this is passed from the Rails view
  };
}
