import {call, put, putResolve, takeLatest, all} from 'redux-saga/effects';
import {PLAYED_AUDIO, SET_USER_FOLDER_LANGUAGE, FETCH_SURVEY, FETCH_SURVEY_MODULE, FETCH_SURVEYS, FETCH_CREATE_SCORE, FETCH_RESET_SCORE, fetchSurveyOk, fetchSurveyScore, setOrganizationSpecial, setOrganizationData} from './actions';
import Api from '../../services/api';
import { redirectTo } from "../../actions";

// Config
import routes from '../../config/routes';


function * fetchSurveysSaga(action) {
  try {
    const response = yield call(Api.handleGet, routes.surveys.indexSurveys, {}, {});

    if(response.status) {
      yield put(setOrganizationSpecial(response.special_organization))
      yield put(setOrganizationData(response))
      action.data.callback(response);
    } else {
      yield put(redirectTo(routes.client.home));
    };
  } catch (error) {
    // yield putResolve(redirectTo(routes.client.home));
  }
}

function* setUserFolderLanguage(action) {

  try {
    let response = yield call(
      Api.handlePut,
      routes.surveys.setUserFolderLanugage(
        action.data.userFolderId
      ),
      {},
      action.data
    );

    if (response.status) {
      action.data.success(response);
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}


function * fetchSurveySaga(action) {
  try {
    const response = yield call(Api.handleGet, routes.surveys.showSurvey(action.data.token), {}, {});

    if(response.status) {
      yield put(fetchSurveyOk(response.data));
      action.data.callback(response.data);
    } else {
      yield put(redirectTo(routes.client.home));
    };
  } catch (error) {
    // yield putResolve(redirectTo(routes.client.home));
  }
}

function * fetchSurveyModuleSaga(action) {
  try {
    const response = yield call(Api.handleGet, routes.surveys.showModule(action.data.token, action.data.module_id), {}, {});

    if(response.status) {
      yield put(setOrganizationSpecial(response.special_organization))
      yield put(setOrganizationData(response))
      action.data.success(response.data);
    } else {
      action.data.error(response);
    };
  } catch (error) {
    console.log(error)
    // yield putResolve(redirectTo(routes.client.home));
  }
}

export function * fetchCreateScoreSaga(action) {
  try {
    const response = yield call(Api.handlePost, routes.scores.createScore(action.data.token), {}, {answer: action.data.answer});

    if(response.status) {
      yield put(fetchSurveyScore(response.data));
      action.data.success(response.data);
    };
  } catch (error) {
    //action.data.error(response.data);
    // yield putResolve(redirectTo(routes.client.home));
  }
}

export function * playedAudioSaga(action) {
  try {
    const response = yield call(Api.handlePost, routes.surveys.setPlayedAudio(), {}, {token: action.data.token, identifier: action.data.identifier});

    if(response.status) {
      action.data.success(response.data);
    };
  } catch (error) {
    //action.data.error(response.data);
    // yield putResolve(redirectTo(routes.client.home));
  }
}

export function * fetchResetScoreSaga(action) {
  try {
    const response = yield call(Api.handleDelete, routes.scores.resetScore(action.data.organizationId, action.data.folder_id, action.data.id), {}, {});

    if(response.status) {
      yield put(fetchSurveyScore(response.data));
    };
  } catch (error) {
    // yield putResolve(redirectTo(routes.client.home));
  }
}

export function * watchSurveys() {
  yield all([
    takeLatest(FETCH_SURVEY, fetchSurveySaga),
    takeLatest(FETCH_SURVEYS, fetchSurveysSaga),
    takeLatest(FETCH_CREATE_SCORE, fetchCreateScoreSaga),
    takeLatest(FETCH_RESET_SCORE, fetchResetScoreSaga),
    takeLatest(FETCH_SURVEY_MODULE, fetchSurveyModuleSaga),
    takeLatest(SET_USER_FOLDER_LANGUAGE, setUserFolderLanguage),
    takeLatest(PLAYED_AUDIO, playedAudioSaga)
  ])
}
