import React, { useState, useEffect, useRef } from "react";

import { motion, AnimatePresence } from "framer-motion";
import classNames from "classnames";

// Items
import SurveyCheckboxItem from "./items/SurveyCheckboxItem";
import SurveyStarItem from "./items/SurveyStarItem";
import SurveyIpsativeItem from "./items/SurveyIpsativeItem";
import SurveyWysiwygItem from "./items/SurveyWysiwygItem";
import SurveyImagesIpsativeItem from "./items/SurveyImagesIpsativeItem";
import SurveyCheckboxImageItem from "./items/SurveyCheckboxImageItem";
import SurveyTrueFalseItem from "./items/SurveyTrueFalseItem";
import SurveyRelationalItem from "./items/SurveyRelationalItem";
import SurveyOrderItem from "./items/SurveyOrderItem";
import SurveyCheckboxAudioItem from "./items/SurveyCheckboxAudioItem"

const itemTypesCollection = (item, props, callbacks) => {
  return {
    0: <SurveyCheckboxItem {...props} />,
    1: <SurveyCheckboxItem {...props} />,
    5: <SurveyStarItem {...props} checkboxMode={false} />,
    6: <SurveyIpsativeItem {...props} {...callbacks} />,
    7: <SurveyStarItem {...props} checkboxMode={true} />,
    8: <SurveyStarItem {...props} checkboxMode={false} labeled={true} />,
    9: <SurveyWysiwygItem {...props} {...callbacks} />,
    10: <SurveyImagesIpsativeItem {...props} />,
    11: <SurveyCheckboxItem {...props} />,
    12: <SurveyCheckboxImageItem {...props} />,
    13: <SurveyTrueFalseItem {...props} />,
    14: <SurveyRelationalItem {...props} />,
    15: <SurveyOrderItem {...props} />,
    16: <SurveyCheckboxAudioItem {...props} />,
  }[item];
};

const SurveyQuestion = (props) => {
  const [titleClasses, setTitleClasses] = useState("c-survey-title");
  const [answer, setAnswer] = useState({ items: {} });
  const [checkboxAnswers, setCheckboxAnswers] = useState({});
  const [trueFalseAnswers, setTrueFalseAnswers] = useState({});

  const introImageRef = useRef(null);

  useEffect(() => {
    $(window).scrollTop(0);
  }, []);

  useEffect(() => {
    let temp_answer = JSON.parse(JSON.stringify(props.answer));
    if (
      props.data.hasOwnProperty("example") &&
      props.answer &&
      Object.keys(props.answer).length > 0
    ) {
      let items = temp_answer.items;

      if (
        Object.keys(items).includes(props.data.example) ||
        Object.keys(items).includes(props.data.example.toString())
      ) {
        let answer_correct = items[props.data.example.toString()] || items[props.data.example];
        answer_correct["correct"] = true;
        items = { ...items, [props.data.example]: answer_correct };
      } else {
        items = { ...items, [props.data.example]: { correct: true } };
      }

      temp_answer["items"] = items;
    }
    setAnswer(temp_answer);

    // setAspectRatio();

  }, [props.answer]);

  // const setAspectRatio = () => {
  //   if(introImageRef.current && introImageRef.current.naturalWidth) {
  //     if(introImageRef.current.naturalWidth > 0 && introImageRef.current.naturalHeight > 0) {
  //       introImageRef.current.style.aspectRatio = introImageRef.current.naturalWidth / introImageRef.current.naturalHeight;
  //     }
  //   }
  // }

  useEffect(() => {
    let temp_answer = JSON.parse(JSON.stringify(props.answer));
    if (
      props.data.hasOwnProperty("example") &&
      props.answer &&
      Object.keys(props.answer).length > 0
    ) {
      let items = temp_answer.items;

      if (
        Object.keys(items).includes(props.data.example) ||
        Object.keys(items).includes(props.data.example.toString())
      ) {
        let answer_correct = items[props.data.example.toString()] || items[props.data.example];
        answer_correct["correct"] = true;
        items = { ...items, [props.data.example]: answer_correct };
      } else {
        items = { ...items, [props.data.example]: { correct: true } };
      }

      temp_answer["items"] = items;
    }
    setAnswer(temp_answer);
  }, [props.data.identifier]);

  useEffect(() => {
    setTitleClasses(
      classNames(
        "c-survey-title",
        props.data.item_format_style !== 5 && "o-margin--bottom-size-xl@md-and-up o-margin--bottom",
        (props.data.item_format_style === 5 ||
          props.data.item_format_style === 7 ||
          props.data.item_format_style === 8) &&
          "o-margin--bottom-size-xxl o-text-align--center",
        props.data.title &&  props.data.title.length >= 155 && "c-survey-title--extra-small",
        props.data.title && props.data.title.length >= 55 && "c-survey-title--small",
        props.data.hasOwnProperty("example") && "c-survey-title-example",
      ),
    );

    try {
      let temp_answer = JSON.parse(JSON.stringify(props.answer));
      let items = temp_answer.items;
      let keys = [];
      if (items) {
        keys = Object.keys(items);
      }

      if (
        props.answer &&
        Object.keys(props.answer).length > 0 &&
        items &&
        items[keys[0]].hasOwnProperty("multi_checkbox") &&
        Object.keys(checkboxAnswers).length === 0
      ) {
        if (keys.length > 0) {
          let items_found = {};
          if (items[keys[0]].hasOwnProperty("multi_checkbox")) {
            items[keys[0]].multi_checkbox.forEach(
              (k) => (items_found = { ...items_found, [k]: props.data.items[k] }),
            );
          }
          setCheckboxAnswers(items_found);
        }
      } else {
        setCheckboxAnswers({});
      }
    } catch (e) {}
  }, [props.data.title]);

  const _played_audio = (identfier, success) => {
    props.setPlayedAudio(identfier, success)
  }
  const _handleQuestion = (questionData, answer_parameter, item_format) => {
    let questionItem = { title: questionData.title, items: props.answer.items };

    if (props.data.hasOwnProperty("example")) {
      Object.keys(answer_parameter).forEach(function (key) {
        answer_parameter[key] = { ...answer_parameter[key], example: true };
      });
    }

    if (item_format === 1 || props.data.item_format_style === 14) {
      questionItem["items"] = { ...(props.answer["items"] || {}), ...answer_parameter };
    } else if (item_format === 0) {
      // Unique select
      questionItem["items"] = answer_parameter;
    }

    if (props.data.item_format_style === 11) {
      //remove if exists key
      // add if not exist key
      let temporary_hash = { ...checkboxAnswers, ...answer_parameter };
      let answer_key = Object.keys(answer_parameter)[0];

      if (Object.keys(checkboxAnswers).includes(answer_key)) {
        delete checkboxAnswers[answer_key];
        setCheckboxAnswers(checkboxAnswers);
        temporary_hash = checkboxAnswers;
      } else {
        setCheckboxAnswers({ ...checkboxAnswers, ...answer_parameter });
      }
      let sum_values = [];
      let total_keys = Object.keys(temporary_hash);
      total_keys.forEach((key) => sum_values.push(temporary_hash[key].value));
      let sum_total = sum_values.reduce((a, b) => a + b, 0);
      let temp_answer_parameter = JSON.parse(JSON.stringify(answer_parameter));

      if (sum_total === props.data.sum_values_to_correct && !sum_values.includes(0)) {
        temp_answer_parameter[answer_key]["value"] = 1;
        temp_answer_parameter[answer_key]["multi_checkbox"] = total_keys;
        questionItem["items"] = temp_answer_parameter;
      } else {
        temp_answer_parameter[answer_key]["value"] = 0;
        temp_answer_parameter[answer_key]["multi_checkbox"] = total_keys;
        questionItem["items"] = temp_answer_parameter;
      }
    }

    if (props.data.item_format_style === 13) {
      let total_keys = Object.keys(props.data.items);
      setTrueFalseAnswers({...trueFalseAnswers, ...answer_parameter});
      // return false;
    }

    return props.handleClick(questionItem);
  };

  useEffect(() => {
  }, [trueFalseAnswers]);

  const _checkFormatStyle = (item_format_style) => {
    return [5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 16].includes(item_format_style);
  };
  console.log("pp1", props)
  const _renderItem = (questionData, item_format_style, item_format, index = -1) => {
    let selectedItems = answer["items"] || {};
    return itemTypesCollection(item_format_style, {
      questionData,
      selectedItems,
      index,
      example: props.data.hasOwnProperty("example") ? props.data.example.toString() : undefined,
      step: props.step,
      checkboxAnswers: checkboxAnswers,
      item_format_style: item_format_style,
      handleClick: (scoreData) => {
        console.log("get score data", scoreData);
        _handleQuestion(questionData, scoreData, item_format)
      },
      playedAudio: (success) => _played_audio(questionData.identifier, success),
      language: props.internalization.getLanguage(),
    });
  };


  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        key={props.step}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >

      {/* Using intro_title as Asturias flag */}
      {(!props.data.intro_title && props.data.title) && (
        <h2 className={titleClasses} dangerouslySetInnerHTML={{ __html: props.data.title }} />
      )} 

        {(props.data.intro_title) && (
          <h2 className="c-survey-title o-margin--bottom-size-xl@md-and-up o-margin--bottom" style={{color: 'var(--primary-color)'}}>{props.data.intro_title}</h2> 
        )}

        {props.data.description && (
          <h3
            className="o-font--bold o-font--size-m o-margin--bottom-size-l"
            dangerouslySetInnerHTML={{ __html: props.data.description }}
          ></h3>
        )}
        {props.data.intro && !props.data.img_intro && (
          <p className="o-margin--bottom-size-l">
            <div dangerouslySetInnerHTML={{ __html: props.data.intro }} />
          </p>
        )}

        {props.data.intro && props.data.img_intro && (
          <div className="c-intro">
            <p className="c-intro__text">
              <img ref={introImageRef} className="c-intro__image" src={props.data.img_intro} alt="intro image" />
              <span dangerouslySetInnerHTML={{ __html: props.data.intro }} />
            </p>
          </div>
        )}

        {
          props.disabled 
            ? <h4 className={titleClasses}>{props.internalization.survey.time_ended}</h4> 
            :_checkFormatStyle(props.data.item_format_style)  
              ? _renderItem(props.data, props.data.item_format_style, props.data.item_format)
              : Object.values(props.data.items).map((item, index) => { return _renderItem(item, props.data.item_format_style, props.data.item_format, index) })
        }

      </motion.div>
    </AnimatePresence>
  );
};

export default SurveyQuestion;
