// Dependencies
import React from "react";
import PropTypes from "prop-types";

const TableData = (props) =>  {

  return (
    <table className="c-table-data c-table-data--striped"> 
      <tbody className="c-table-data__body">
        <tr className="c-table-data__row">
          <td className="c-table-data__cell">Web:</td>
          <td className="c-table-data__cell">www.facthum.com</td>
        </tr>
        <tr className="c-table-data__row">
          <td className="c-table-data__cell">Titular:</td>
          <td className="c-table-data__cell">FACTHUM SPAIN, S.L. (FACTHUM)</td>
        </tr>
        <tr className="c-table-data__row">
          <td className="c-table-data__cell">Domicilio:</td>
          <td className="c-table-data__cell">
            C. José Echegaray, 8. Edificio 3, 1ª planta. Parque empresarial Alvia. Las Rozas de Madrid, Madrid, CP. 28232.
          </td>
        </tr>
        <tr className="c-table-data__row">
          <td className="c-table-data__cell">C.I.F:</td>
          <td className="c-table-data__cell">B85643328</td>
        </tr>
        <tr className="c-table-data__row">
          <td className="c-table-data__cell">Teléfono:</td>
          <td className="c-table-data__cell">91.630.72.02</td>
        </tr>
        <tr className="c-table-data__row">
          <td className="c-table-data__cell">Correo electrónico:</td>
          <td className="c-table-data__cell">info@facthum.com</td>
        </tr>
      </tbody>
    </table>
  );

}

export default TableData;
