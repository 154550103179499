import PropTypes from "prop-types";
import React from "react";
import { motion } from "framer-motion";
import uaxLogo from "../../../assets/images/logos/uax_logo.svg"
import uaxmnLogo from "../../../assets/images/logos/uax_logo_mare_nostrum.svg"
export default class DisclaimerUAXMN extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
    };
  }

  componentDidMount() {
    const footer = document.querySelector(".c-footer ul")
    const logo = document.querySelector(".c-logo a")
    footer.style.display = "none"
    logo.style.display = "none"
  }

  componentWillUnmount() {
    const footer = document.querySelector(".c-footer ul")
    const logo = document.querySelector(".c-logo a")
    footer.style.display = "flex"
    logo.style.display = "block"
  }

  handleCheckbox = (checkbox, state) => {
    this.setState({ isDisabled: !checkbox });
  };


  render() {
    let logo = uaxmnLogo
    return (
      <motion.section
        className="o-wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="o-container">

            <img src={logo} width={200} style={{ marginBottom: 12 }}/>
          <h1>POLÍTICA DE PRIVACIDAD</h1>
          <h2 className="o-margin o-margin--bottom-size-l">PRUEBAS DE ADMISIÓN</h2>

          <section className="o-margin o-margin--bottom-size-l">
            <h3>1. IDENTIFICACIÓN</h3>
            <p><strong>Titular/Responsable del tratamiento:</strong> PROMOTORA EDUCACIÓN SUPERIOR ANDALUCÍA, S.A.U. (en adelante <strong>“UAX Mare Nostrum”</strong>)</p>
            <p><strong>NIF:</strong> A-42792747</p>
            <p><strong>Correo electrónico:</strong> dpd@uax.es</p>
            <p><strong>Dirección:</strong> C/ Casas de Campos 4 - 1ª planta. 29001 Málaga</p>
          </section>

          <section className="o-margin o-margin--bottom-size-l">
            <h3>2. INFORMACIÓN</h3>
            <p>
              Mediante la aceptación de la presente Política de Privacidad, usted queda informado de que los datos personales que
              facilite a través de la página web <a href="https://t3.grupofacthum.com/disclaimer_uaxmn" style={{textDecoration: 'underline'}}>https://t3.grupofacthum.com/disclaimer_uaxmn</a> (en adelante, el <strong>“Sitio Web”</strong>), así como los datos
              derivados de su navegación y aquellos otros datos que pueda facilitar en un futuro en el mismo, serán tratados por
              UAX Mare Nostrum como responsable del tratamiento con las finalidades indicadas en la presente política.
            </p>
            <p>
              Usted debe leer con atención esta Política de Privacidad, que ha sido redactada de forma clara y sencilla, para
              facilitar su comprensión, pudiendo determinar, de forma libre y voluntaria si desea facilitar sus datos personales
              a UAX Mare Nostrum.
            </p>
          </section>

          <section className="o-margin o-margin--bottom-size-l">
            <h3>3. OBLIGATORIEDAD DE FACILITAR LOS DATOS</h3>
            <p>
              Los datos solicitados en los formularios del Sitio Web son, con carácter general, obligatorios (salvo que en el
              campo requerido se especifique lo contrario) para cumplir con las finalidades para las que están siendo recabados.
            </p>
            <p>
              Por lo tanto, si no facilita los mismos o no los facilita correctamente no podrán atenderse sus solicitudes,
              sin perjuicio de que podrá visualizar libremente ciertos contenidos del Sitio Web.
            </p>
          </section>

          <section className="o-margin o-margin--bottom-size-l">
            <h3>4. ¿CON QUÉ FINALIDADES TRATARÁ UAX Mare Nostrum LOS DATOS PERSONALES?</h3>
            <p>UAX tratará sus datos para las siguientes finalidades específicas:</p>
            <ul>
              <li>
                <strong>a)	Evaluar su prueba de admisión:</strong>
                Sus datos personales serán tratados con la finalidad de evaluar su prueba de admisión en UAX Mare Nostrum a través de las preguntas que se le realicen en los formularios habilitados en el Sitio Web.
              </li>
              <li>
                <strong>b)	Dar cumplimiento a las obligaciones legalmente establecidas:</strong> cuando el tratamiento sea necesario para dar
                cumplimiento a una obligación legal derivada del derecho español o del derecho de la Unión Europa y que pueda
                resultar aplicable a UAX Mare Nostrum (normativa fiscal, por ejemplo).
              </li>
            </ul>
          </section>

          <section className="o-margin o-margin--bottom-size-l">
            <h3>5. ¿QUÉ DATOS PERSONALES TRATARÁ UAX Mare Nostrum?</h3>
            <p>Durante la navegación por el Sitio Web será posible que sean tratados los siguientes datos personales:</p>
            <p><strong>a)	Evaluar su prueba de admisión</strong></p>
            <ul>
              <li><strong>Datos identificativos:</strong> nombre y apellidos, DNI.</li>
              <li><strong>Datos de contacto:</strong> correo electrónico, teléfono, domicilio.</li>
              <li><strong>Datos de características personales:</strong> fecha de nacimiento, nacionalidad.</li>
              <li><strong>Datos académicos:</strong> resultados de las pruebas de admisión.</li>
            </ul>

            <p><strong>b)	Dar cumplimiento a las obligaciones legalmente establecidas</strong></p>
            <p>Cualquier dato que pueda ser requerido legalmente, en particular:</p>
            <ul>
              <li><strong>Datos identificativos:</strong> nombre y apellidos, DNI.</li>
              <li><strong>Datos de contacto:</strong> correo electrónico, teléfono, domicilio.</li>
              <li><strong>Datos de características personales:</strong> fecha de nacimiento, nacionalidad.</li>
              <li><strong>Datos académicos:</strong> resultados de las pruebas de admisión.</li>
              <li>Cualquier otro dato que haya podido ser requerido</li>
            </ul>

            <p>En caso de que facilite datos de terceros, manifiesta contar con el consentimiento de estos y/o legitimación suficiente
              y se compromete a trasladarles la información contenida en la Política de Privacidad, eximiendo a UAX Mare Nostrum de cualquier
              responsabilidad en este sentido.
            </p>

            <p>
              No obstante, UAX Mare Nostrum podrá llevar a cabo las verificaciones oportunas para constatar este hecho, adoptando las medidas de 
              diligencia debida que correspondan, conforme a la normativa de protección de datos.
            </p>
          </section>

          <section className="o-margin o-margin--bottom-size-l">
            <h3>6. ¿CUÁL ES LA LEGITIMACIÓN DEL TRATAMIENTO DE LOS DATOS PERSONALES POR PARTE DE UAX Mare Nostrum?</h3>
            <p>
              UAX Mare Nostrum trata sus datos conforme a las siguientes bases legitimadoras:
            </p>
            <ul>
              <li><strong>a) Evaluar su prueba de admisión:</strong> el tratamiento de datos derivado de esta finalidad tendrá como base de
                legitimación la aplicación de medidas precontractuales entre usted y UAX Mare Nostrum como consecuencia de la evaluación de
                su prueba.
              </li>
              <li><strong>b) Dar cumplimiento a las obligaciones legalmente establecidas:</strong> todos los tratamientos derivados de esta
                finalidad tendrán como base el cumplimiento de una obligación legal.
              </li>
            </ul>
          </section>

          <section className="o-margin o-margin--bottom-size-l">
            <h3>7. CONSERVACIÓN DE LOS DATOS</h3>
            <p>
              Los datos personales serán conservados por UAX Mare Nostrum durante los siguientes plazos:
            </p>
            <ul>
              <li><strong>a) Evaluar su prueba de admisión:</strong> los datos serán conservados mientras se desarrolle el proceso de evaluación
                de su prueba de admisión y hasta que se adopte una decisión al respecto, y serán bloqueados por el plazo de
                prescripción de las responsabilidades que se puedan derivar de su participación.
              </li>
              <li><strong>b) Dar cumplimiento a las obligaciones legalmente establecidas:</strong> los datos facilitados para dar cumplimiento
                a las obligaciones derivadas de los servicios ofrecidos por UAX Mare Nostrum se conservarán durante los plazos establecidos
                legalmente.
              </li>
            </ul>
          </section>

          <section className="o-margin o-margin--bottom-size-l">
            <h3>8. ¿CON QUÉ DESTINATARIOS SE PODRÁN COMPARTIR LOS DATOS PERSONALES?</h3>
            <p>
              Todos los datos personales facilitados a través del Sitio Web podrán ser comunicados a:
            </p>
            <ul>
              <li>a) Administraciones Públicas, en los casos previstos en la ley, con base en el cumplimiento de una obligación legal.</li>
              <li>b) Juzgados y Tribunales, en los casos previstos en la ley, con base en el cumplimiento de una obligación legal.</li>
            </ul>
            <p>
              Además, UAX Mare Nostrum contará con la colaboración de terceros proveedores de servicios que pueden tener acceso a sus datos personales, 
              los cuales tratarán en nombre y por cuenta de UAX Mare Nostrum sus datos, con motivo de la prestación de servicios. UAX Mare Nostrum sigue unos rigurosos 
              procedimientos de selección de proveedores con el fin de dar cumplimiento a sus obligaciones en materia de protección de datos, 
              incluida la suscripción del correspondiente contrato de tratamiento de datos de conformidad con el art. 28 del RGPD.
            </p>
            <p>
              Concretamente, UAX Mare Nostrum contará con la prestación de servicios de mantenimiento del Sitio Web prestados por el proveedor Facthum Spain, S.L.
            </p>
          </section>

          <section className="o-margin o-margin--bottom-size-l">
            <h3>9. TRANSFERENCIAS INTERNACIONALES DE DATOS</h3>
            <p>
              Es posible que algunos terceros proveedores de UAX Mare Nostrum se encuentren situados en terceros países fuera del Espacio Económico
              Europeo, lo que podría implicar una transferencia internacional de sus datos personales.
            </p>
            <p>
              En todo caso, UAX Mare Nostrum ha regularizado todas las transferencias internacionales de datos conforme se dispone en el
              Reglamento (UE) 2016/679, General de Protección de Datos.
            </p>
          </section>

          <section className="o-margin o-margin--bottom-size-l">
            <h3>10. RESPONSABILIDAD DEL USUARIO</h3>
            <p>
              Usted garantiza que es mayor de catorce (14) años y que los datos que facilita a UAX Mare Nostrum son verdaderos, exactos, completos y actualizados.
              A estos efectos, usted responde de la veracidad de todos los datos que comunique y mantendrá convenientemente actualizada 
              la información facilitada, de tal forma que responda a su situación real. 
            </p>
            <p>
              Del mismo modo, garantiza que ha informado a los terceros de los que facilite sus datos, en caso de hacerlo, de los aspectos 
              contenidos en este documento. Asimismo, garantiza que ha obtenido su autorización para facilitar sus datos a UAX Mare Nostrum para los fines señalados.
            </p>
            <p>
              En todo caso, usted será responsable de las informaciones falsas o inexactas que proporcione a través del Sitio Web 
              y de los daños y perjuicios, directos o indirectos, que ello cause a UAX Mare Nostrum o a terceros.
            </p>
          </section>

          <section className="o-margin o-margin--bottom-size-l">
            <h3>11. EJERCICIO DE DERECHOS</h3>
            <p>
              Como titular de los datos, usted puede enviar un escrito a UAX Mare Nostrum, a la dirección indicada en el encabezado de la presente Política de Privacidad,
              o bien por medio de un correo electrónico a la dirección: <a href="mailto:dpd@uax.es">dpd@uax.es</a>, en cualquier momento y de manera gratuita, para ejercer los siguientes derechos: 
            </p>
            <ul>
              <li><strong>a) Derecho de Acceso:</strong> Tendrá derecho a que UAX Mare Nostrum le informe sobre si está tratando o no sus datos personales 
                y, en tal caso, poder acceder a dichos datos y recibir información sobre los fines para los que son tratados, las categorías de 
                datos afectados por el tratamiento, los destinatarios a los que se comunicaron sus datos personales y el plazo previsto de 
                conservación de los datos, entre otra información.
              </li>
              <li><strong>b) Derecho de Rectificación y Supresión:</strong> Tendrá derecho a solicitar la supresión de datos personales siempre que se cumplan los requisitos legales, 
                y la rectificación de los datos inexactos que le conciernan cuando, entre otros motivos, éstos ya no sean necesarios para los fines para los que fueron recogidos. 
                legalmente.
              </li>
              <li><strong>c)Limitación del tratamiento, revocación del consentimiento y oposición total o parcial al tratamiento:</strong> En determinadas circunstancias 
                (por ejemplo, en caso de que el solicitante impugne la exactitud de sus datos, mientras se verifica la exactitud de los mismos), podrá solicitar que se 
                limite el tratamiento de sus datos personales, siendo estos únicamente tratados para el ejercicio o la defensa de reclamaciones.
                También tendrá derecho a revocar el consentimiento prestado y a oponerse al tratamiento en cualquier momento, por motivos relacionados con su situación
                particular, en caso de que el tratamiento esté basado en nuestro interés legítimo.
              </li>
              <li><strong>d) Portabilidad de sus datos:</strong> Tendrá derecho a recibir los datos personales que haya facilitado a UAX Mare Nostrum en un formato estructurado, común y de lectura mecánica, 
                y a poder transmitirlos a otro responsable del tratamiento sin que el responsable al que se los hubiera facilitado se lo impida, en los supuestos legalmente previstos a estos efectos.
              </li>
              <li><strong>e) Decisiones individuales automatizadas:</strong> Asimismo, además de los derechos mencionados, en caso de que se adopten decisiones automatizadas, incluida la elaboración 
                de perfiles, tendrá derecho a obtener intervención humana por parte de UAX Mare Nostrum y a expresar su punto de vista y a impugnar la decisión. 
              </li>
              <li><strong>f) Otros:</strong> Igualmente, cuando se transfieran datos personales a un tercer país o a una organización internacional, tendrá derecho a ser informado 
                sobre cómo se puede acceder u obtener copia de las garantías adecuadas relativas a la transferencia. 
                <br/>
                <br/>
                Asimismo, podrá interponer una reclamación relativa a la protección de sus datos personales ante la Agencia Española de Protección de Datos en la web 
                <a href="www.aepd.es">www.aepd.es</a> o en la dirección C/ Jorge Juan, 6, 28001 - Madrid, cuando el interesado considere que UAX Mare Nostrum ha vulnerado los derechos que le son reconocidos por la normativa aplicable en protección de datos. 
 
              </li>
            </ul>
          </section>

          <section className="o-margin o-margin--bottom-size-l">
            <h3>12. MEDIDAS DE SEGURIDAD</h3>
            <p>
              UAX Mare Nostrum tratará sus datos en todo momento de forma absolutamente confidencial y guardando el preceptivo deber de secreto respecto de los mismos, 
              de conformidad con lo previsto en la normativa de aplicación, adoptando al efecto las medidas de índole técnica y organizativas necesarias 
              que garanticen la seguridad de sus datos y eviten su alteración, pérdida, tratamiento o acceso no autorizado, habida cuenta del estado de la tecnología, 
              la naturaleza de los datos almacenados y los riesgos a que están expuestos.
            </p>
          </section>

          <section className="o-margin o-margin--bottom-size-l">
            <h3>13. CAMBIOS</h3>
            <p>
              UAX Mare Nostrum se reserva el derecho de revisar su Política de Privacidad en el momento que lo considere oportuno, en cuyo caso se comunicará a los usuarios. 
              Por esta razón, le rogamos que compruebe de forma regular esta declaración de privacidad para leer la versión más reciente de la Política de Privacidad de UAX Mare Nostrum.
            </p>
          </section>

          <section className="o-margin o-margin--bottom-size-l">
            <h3>14. ACEPTACIÓN Y CONSENTIMIENTO</h3>
            <p>
              Usted declara haber sido informado de las condiciones sobre protección de datos personales, aceptando el contenido de la presente Política de Privacidad.
            </p>
            <p><em>Última actualización: mayo de 2024</em></p>
          </section>
        </div>
      </motion.section>
    );
  }

  static propTypes = {
    // name: PropTypes.string.isRequired, // this is passed from the Rails view
  };
}
