import React, {useState} from "react";
import Checkbox from "../../../../components/Checkbox";

const SurveyCheckboxImageItem = (props) => {
  const [played, setPlayed] = useState(props.questionData.played || false);

  console.log(props)
  const _handleCheckbox = (index, data) => {
    return props.handleClick({ [index]: data });
  };


  const _isSelected = (index) => {
    return Object.keys(props.selectedItems).includes(index.toString())  
  }

  const startPlay = () => {
    document.getElementById('audioPlayer').play();
    //call played
    setPlayed(true)
    props.playedAudio(() => console.log("success"))
    console.log("playing")
  }
  console.log(played)
  return (
    <>
      {props.questionData.item_context && (
        <p className="c-item-format-style-12__item-context">{props.questionData.item_context}</p>
      )}
      {props.questionData.img_context && (
        <>
          <audio id="audioPlayer" controls style={{display: 'none'}}>
            <source src={props.questionData.img_context} type="audio/mpeg" />
              Your browser does not support the audio element.
          </audio>
          
        </>
      )}
      { !played && props.questionData.img_context &&
        <div style={{textAlign: 'center', marginBottom: 10}}>
          <button onClick={() => startPlay()}>▶ Play</button>
        </div>
      }
      {
        (played || !props.questionData.img_context) &&
        <div style={{textAlign: 'center', marginBottom: 10}}>
          <p>Playing or played before</p>
        </div>
      }
      {props.questionData.title && props.questionData.intro_title && (
        <p className="c-item-format-style-12__item-title">{props.questionData.title}</p>
      )}
      <ul className="c-item-format-style-12__item-list">
        {Object.values(props.questionData.items).map((item, index) => {
          return (
            <li key={`${item.text}-${index}`} className="o-margin--bottom-size-s o-display--flex">
                <Checkbox 
                  // text={item.name || item.text}
                  text={''}
                  value={item.value}
                  onClick={() => _handleCheckbox(index, item)}
                  checked={_isSelected(index)}
                  className="o-display--flex"
                  groupClass="c-checkbox__tick-illustration-color--primary"
                  // correct={_isCorrect()}
                  item_format_style={props.item_format_style}
                />
                {item && item.img && (<img src={item.img} alt={item.text} className="c-item-format-style-12__item-image" />)}
                {item && item.text && (<p className="c-item-format-style-12__item-text">{item.text}</p>)}
            </li>
          )
        })}
      </ul>
    </>
  )


};

export default SurveyCheckboxImageItem;
